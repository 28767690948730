'use strict';

angular.module('cpformplastApp.items')
  .config(function ($stateProvider) {
    $stateProvider
      .state('item-planning', {
        url: '/item-planning',
        templateUrl: 'app/item-planning/item-planning.html',
        controller: 'itemPlanningController',
        authenticate: ['admin','management_dept','mold_dept','receptionist']
      });
  });
